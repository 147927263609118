<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="owners-page">

    <section v-if="!loading">
      <div class="row">
        <div class="col-12">
          <b-card class="">
            <section v-if="category" class="row">
              <div class="col-md-3">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="TabletIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Name
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      {{ category.name }}
                    </h4>
                  </b-media-body>
                </b-media>
              </div>

              <div class="col-md-4">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="CheckCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Max Items
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      <span v-if="category.max_items === -1">No Limit</span>
                      <span v-else>{{ category.max_items }}</span>
                    </h4>
                  </b-media-body>
                </b-media>
              </div>

            </section>
            <section v-else>Could not get owner</section>
          </b-card>
        </div>
      </div>

      <b-card no-body class="mb-0">

        <div class="m-2">
          <b-row>
            <b-col v-if="$can('Create', 'Owner')" cols="12" md="3" lg="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <b-button @click="$bvModal.show('modal-add-category')" variant="primary">Create Subcategory</b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="9" lg="10">
              <div class="d-flex align-items-center justify-content-end">

                <b-input-group>
                  <b-form-input
                    @keydown.enter="searchSubCategories()"
                    v-model="searchVal"
                    class="d-inline-block"
                    placeholder="Search for a category..."
                  />
                  <b-input-group-append>
                    <b-button @click="searchSubCategories()">Search</b-button>
                  </b-input-group-append>
                </b-input-group>

                <section class="col-2 p-0 ml-1 d-flex justify-content-end align-items-center">
                  <b-button @click="clearFilters()">Clear filters</b-button>
                </section>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="data-table"
          hover
          :items="categories"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          @sort-changed="onSortChanged"
        >
          <template #cell(name)="data">
              {{data.item.name}}
          </template>
          <template #cell(max_items)="data">
            <span v-if="data.item.max_items === -1">No limit</span>
            <span v-else>{{data.item.max_items}}</span>
          </template>
          <template #cell(exclude_from_location_max_items)="data">
            <span v-if="data.item.exclude_from_location_max_items">Yes</span>
            <span v-else>No</span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Owner')"
                                      @click="showModal(data.item, 'modal-update-category')">
                Update subcategory
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Owner')"
                                      @click="showModal(data.item, 'modal-delete-category')">
                Delete subcategory
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreOwners" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="p-2 pl-3" v-if="total_count === 0">No Subcategories found</b-col>
        </b-row>

      </b-card>

      <b-modal id="modal-add-category" title="Add new category" hide-footer>
        <add-category :parent-id="filters.parent_id" @refresh="refreshCategories" @close="closeModals"></add-category>
      </b-modal>

      <b-modal id="modal-delete-category" title="Delete subcategory" hide-footer>
        <delete-modal
          @close="closeModals()" @delete="deleteSubcategory(targetCategory)"
          :subtitle="category.identifier"
          title="Are you sure you wish to delete this subcategory?"></delete-modal>
      </b-modal>

      <b-modal id="modal-update-category" title="Update category" hide-footer>
        <update-category v-if="category" :target-category="targetCategory" @refresh="refreshCategories" @close="closeModals()"></update-category>
      </b-modal>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading Sub Categories"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
  import DeleteModal from "@/components/modals/DeleteModal.vue";
  import CategoriesService from "@/services/PossessionCategoriesService";
  import AddCategory from "@/views/possessions/modals/AddCategory.vue";
  import UpdateCategory from "@/views/owners/sections/UpdateCategory.vue";

  export default {
    name: 'CategoryView',
    components: {
      UpdateCategory,
      AddCategory,
      DeleteModal,
    },
    data() {
      const parentId = this.$route.params.id;
      return {
        loading: true,
        category: null,
        targetCategory: null,
        headers: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'max_items',
            label: 'Max Items',
          },
          {
            key: 'exclude_from_location_max_items',
            label: 'Excluded from location max items'
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        categories: [],
        searchVal: '',
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
          search: '',
          parent_id: parseInt(parentId),
        },
        total_count: 0,
      };
    },
    mounted() {
      this.getCategory();
      this.getSubCategories();
    },
    methods: {
      clearFilters() {
        this.currentPage = 1
        this.searchVal = ''
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
          parent_id: this.$route.params.id,
        }
        this.getSubCategories()
      },
      refreshCategories() {
        this.closeModals();
        this.getSubCategories()
      },
      closeModals() {
        this.$bvModal.hide('modal-add-category');
        this.$bvModal.hide('modal-delete-category');
        this.$bvModal.hide('modal-update-category');
      },
      getCategory() {
        this.loading = true;
        CategoriesService.get(this.$route.params.id).then(res => {
          this.category = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get owner, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      searchSubCategories() {
        this.filters.search = this.searchVal
        this.currentPage = 1;
        this.filters.page = 0;
        this.getSubCategories();
      },
      getSubCategories() {
        CategoriesService.listCategories(this.filters).then(res => {
          this.categories = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get categories, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      showModal(category, modalName) {
        this.targetCategory = category;
        this.$bvModal.show(modalName);
      },
      deleteSubcategory(category) {
        CategoriesService.deleteCategory(category.id).then(() => {
          this.$toast.success(`Deleted sub category ${category.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.refreshCategories();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete category, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      onSortChanged({sortBy, sortDesc}) {
        this.filters.order_by = sortDesc ? 'desc' : 'asc'
        this.filters.order = sortBy
        this.searchSubCategories()
      },
    },
  };
</script>
<style>
.manage-btn {
  margin-top: 5px;
}
</style>
